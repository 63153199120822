import { Component } from "vue-property-decorator";
import ProductTile from "./ProductTile";
import queryString from "query-string";
import { Variant } from "@/models/tapwell/Product";
@Component
export default class ProductDetail extends ProductTile {

    constructor() {
        super();
    }

    mounted() {
        this.trackPageView(this.selectedVariant, false);
    }


    public trackPageView(variant: Variant, trackView: boolean): void {
        if (trackView) {
            this.$gtm.trackView("ProductView", variant.url);
        }

        if (window.dataLayer) {
            window.dataLayer.push({
                event: "product-view",
                ecommerce: {
                    detail: {
                    products: [
                            {
                                name: variant.productName,
                                id: variant.id,
                                price: variant.discountPrice,
                                category: variant.categoryPath,
                                variant: variant.name
                            }
                        ]
                    }
                }
            });
        }
    }

    public select(variant: Variant): void {
        this.selectedVariant = variant;
        this.trackPageView(this.selectedVariant, false);
        var currentlocation = location.pathname + location.search;

        if (currentlocation !== variant.url){
            this.trackPageView(variant, true);
            window.history.pushState(null, "", variant.url);
            document.title = variant.name;
        }

        this.getProductPriceAndStock(variant, 0);
    }

    public selectVariantFromQueryString(){
        var query = queryString.parse(location.search);
        if (query.v) {
            const variantIndex = this.product.variants.findIndex((variant) => variant.urlName === query.v);
            if (variantIndex === -1) {
                this.select(this.product.variants[0]);
            } else {
                let variant = this.product.variants[variantIndex];
                this.select(variant);
            }
        } else {
            this.select(this.product.variants[0]);
        }
    }

    public created() {
        this.selectVariantFromQueryString(); 
        const self = this;
        window.onpopstate = function() {
            self.selectVariantFromQueryString(); 
        };
    }

    public openPdf(){
        var token = window.localStorage.getItem("requestVerificationToken");
        var verificationToken='';
        if(token!=null){
            verificationToken = token.split(':')[0];
        }

        if(verificationToken!=''){
            var pdfUrl = this.selectedVariant.generatePdfUrl+"&token="+verificationToken;
            window.open(pdfUrl,"_blank");
        }
    }
}
