import { render, staticRenderFns } from "./ProductDetailB2C.vue?vue&type=template&id=388f2bd4&scoped=true&"
import script from "./ProductDetailB2C.ts?vue&type=script&lang=ts&"
export * from "./ProductDetailB2C.ts?vue&type=script&lang=ts&"
import style0 from "./ProductDetailB2C.vue?vue&type=style&index=0&id=388f2bd4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388f2bd4",
  null
  
)

export default component.exports