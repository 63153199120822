import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { CheckoutStore } from "@/store/tapwell/checkoutstate";
import { Spinner } from "spin.js";
import { AddOrUpdateCartItem, OrderRow, OrderRowCommentsObject } from "@/models/tapwell/Cart";
import { TapwellGroupWebsite } from "@/models/TapwellGroupWebsite";
import { GtmCartProduct,ImportToCart } from "@/models/tapwell/Cart";
import { ApiService } from "@/services/tapwell/ApiService";
import { CampaignModel } from "@/models/tapwell/Checkout";

@Component
export default class CartView extends Vue {    
    @Prop()
    public showBulkImportInCheckout!:boolean;
    @Prop(String)
    public bannerHeadingColor!:string;
    public translations: any;
    protected spinner: Spinner;
    public activeOrderRow: number = NaN;
    private quantityChangedTriggered: boolean = false;
    get cart() { return CheckoutStore.cart; }
    get isHavenB2B() {
        return window.__currentWebsite === TapwellGroupWebsite.HavenB2B;
    }
    public quantityDisplay: number = 0;
    public newQuantity: string = "0";
    protected campaignCode: string = "";
    protected campaignCodeError: string = "";
    public files:any;
    public importedFileName: string = "";
    public excelRows:ImportToCart[];   
    $refs!: {
        vue: Vue,
        fileupload: HTMLInputElement,
        vues: Vue[],
        elements: HTMLInputElement[]
      }

      constructor() {
        super();
        
        this.translations = window.__litium.translation;
        this.spinner = new Spinner({ scale: 0.5 });
        var currentCampaignCode = window.localStorage.getItem('campaignCode');
        this.campaignCode = currentCampaignCode == null ? "" : currentCampaignCode;
        this.files=new FormData();
        this.excelRows= [];
    }

    public trackPageViewAndPushToGtm(): void {
        this.$gtm.trackView("Checkout", this.cart.checkoutUrl);

        let gtmProducts: GtmCartProduct[] = [];

        if (window.dataLayer && this.cart.orderRows) {
            this.cart.orderRows.forEach((row) => {
                gtmProducts.push({
                    'name': row.productName,
                    'id': row.articleNumber,
                    'price': row.totalPriceWithoutCurrencyString,
                    'category': row.category,
                    'variant': row.name,
                    'quantity': row.quantity
                })
            });

            if (gtmProducts) {
                window.dataLayer.push({
                    'event': "checkout",
                    'ecommerce': {
                        'checkout': {
                            'actionField': { 'step': 1 },
                            'products': gtmProducts
                        }
                    }
                });
            }
        }
    }

    @Watch("cart")
    public onCartChanged() {
        if (this.cart) {
            this.trackPageViewAndPushToGtm();
        }
    }

    public activateOrderRow(index: number) {
        this.activeOrderRow = index;
        this.quantityDisplay = this.cart.orderRows[this.activeOrderRow].quantity;
        this.newQuantity = this.cart.orderRows[this.activeOrderRow].quantity.toString();
    }

    public updateLabeling = (row: OrderRow) => {
        setTimeout(() => {
            const data: OrderRowCommentsObject = { 
                comment: "",
                requestedDeliveryDate: "", 
                ...JSON.parse(row.comments || "{}"),
            };

            data.comment = row.comment;
            // console.log("updateLabeling: ", data);

            const payload: AddOrUpdateCartItem = {
                articleNumber: row.articleNumber,
                quantity: row.quantity,
                rowSystemId: row.rowSystemId,
                comment: JSON.stringify(data),
            };

            this.updateCart(payload);
        }, 1500);
    }

    public decreaseAmount(): void {
        this.quantityDisplay -= 1;
        if (this.quantityDisplay < 0) {
            this.quantityDisplay = 0;
        }
        this.newQuantity = this.quantityDisplay.toString();
        if (!this.quantityChangedTriggered) {
            this.quantityChangedTriggered = true;
            var self = this;
            setTimeout(function () {
                var orderRow = self.cart.orderRows[self.activeOrderRow];
                const payload: AddOrUpdateCartItem = {
                    articleNumber: orderRow.articleNumber,
                    quantity: self.quantityDisplay,
                    rowSystemId: orderRow.rowSystemId,
                    comment: orderRow.comments
                };
                self.updateCart(payload);
                self.quantityChangedTriggered = false;
            }, 1500);
        }
    }

    public increaseAmount(): void {
        this.quantityDisplay += 1;
        this.newQuantity = this.quantityDisplay.toString();
        if (!this.quantityChangedTriggered) {
            this.quantityChangedTriggered = true;
            var self = this;
            setTimeout(function () {
                var orderRow = self.cart.orderRows[self.activeOrderRow];
                const payload: AddOrUpdateCartItem = {
                    articleNumber: orderRow.articleNumber,
                    quantity: self.quantityDisplay,
                    rowSystemId: orderRow.rowSystemId,
                    comment: orderRow.comments
                };
                self.updateCart(payload);
                self.quantityChangedTriggered = false;
            }, 1500);
        }
    }

    public handleQtyChange(): void {
        this.quantityDisplay = parseInt(this.newQuantity);
        if (!this.quantityChangedTriggered) {
            this.quantityChangedTriggered = true;
            var self = this;
            setTimeout(function () {
                var orderRow = self.cart.orderRows[self.activeOrderRow];
                if(orderRow.outletProduct && self.quantityDisplay > orderRow.outletProductInStockQuantity)
                {
                    self.quantityDisplay = orderRow.outletProductInStockQuantity;
                    self.newQuantity = orderRow.outletProductInStockQuantity.toString();
                }

                const payload: AddOrUpdateCartItem = {
                    articleNumber: orderRow.articleNumber,
                    quantity: self.quantityDisplay,
                    rowSystemId: orderRow.rowSystemId,
                    comment: orderRow.comments
                };
                self.updateCart(payload);
                self.quantityChangedTriggered = false;
            }, 1500);
        }
    }

    public checkMaximumQuantitySize(row: OrderRow): boolean {
        var maxQtyAchieved = false

        if(row.maximumBuyableQuantitiy >0 && this.quantityDisplay >= row.maximumBuyableQuantitiy) {
            maxQtyAchieved= true;
        }

        if(!maxQtyAchieved){
            if(row.outletProduct && row.outletProductInStockQuantity===this.quantityDisplay)
            {
                maxQtyAchieved= true;
            }
        }

        return maxQtyAchieved;
    }

    public calculateBoxSize(row: OrderRow){
        return (row.quantity * row.sqmBox).toFixed(2);
    }

    public removeFromCart(index: number): void {
        var orderRow = this.cart.orderRows[index];
        const payload: AddOrUpdateCartItem = {
            articleNumber: orderRow.articleNumber,
            quantity: 0,
            rowSystemId: orderRow.rowSystemId,
            comment: orderRow.comments
        };
        this.updateCart(payload);
    }

    public isActiveOrderRow(index: number) {
        return this.activeOrderRow == index;
    }

    public setCampaignCode() {
        localStorage.setItem('campaignCode', this.campaignCode);

        const payload: CampaignModel = {
            campaignCode: this.campaignCode
        };

        ApiService.setCampaignCode(payload).then((response) => {
            if (response.status == 200) {
                CheckoutStore.reloadState().then(() => { /* nothing */ });
                this.campaignCodeError = "";
            }
            else if (response.status == 400) {
                var statusText = typeof (response.statusText) === "string" ? JSON.parse(response.statusText) : response.statusText;
                this.campaignCodeError = statusText.modelState.campaignCode[0];
                CheckoutStore.reloadState().then(() => { /* nothing */ });
            }
            else {
                this.campaignCodeError = "";
            }
        });
    }

    private updateCart(payload: AddOrUpdateCartItem): void {
        this.spinner.spin(this.$el as HTMLElement);
        CheckoutStore.updateState(payload).then(() => {
            this.spinner.stop();
        });
    }
   public fileChange(fileList) {
        if(fileList[0].name.split('.').pop()!="xls"){
            alert("Invalid file format");
            return false;
        }
        this.files=new FormData();
        this.files.append("file", fileList[0], fileList[0].name);
        this.importedFileName = fileList[0].name;
        
        const files = this.files;

        this.spinner.spin(this.$el as HTMLElement);
        CheckoutStore.excelImportState(files).then(() => {
            this.excelRows = this.cart.itemsNotAdded;
            this.spinner.stop();                  
            this.$refs.fileupload.value='';
        });
       
   }


   public getSampleCheckoutExcelFile(): void {
    this.spinner.spin(this.$el as HTMLElement);    

    ApiService.getSampleCheckoutExcelFile().then((response) => {
        if (response.status == 200) {

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'CartItems.xls');
            document.body.appendChild(link);
            link.click();

            this.spinner.stop();

        }
        else if (response.status == 400) {
            this.spinner.stop();

        }
        else {
            this.spinner.stop();
        }
    });

   }
   
}
